export default Object.freeze({
    install(Vue) {
        Vue.prototype.getTitle = () => {
            return process.env.VUE_APP_TITLE
        }

        Vue.prototype.notify = function (type, msg, is_alert=false) {
            if(is_alert) {
                alert(msg)
            } else {
                if(type === 'error') {
                    window.$('#alert-wrap').append("<div class=\"alert-content\" style='z-index: 99999;'><i class=\"fa fa-exclamation-circle\"></i> <span>"+msg+"</span></div>")
                } else {
                    window.$('#alert-wrap').append("<div class=\"alert-content\" style='z-index: 99999;'><i class=\"fas fa-check\"></i> <span>"+msg+"</span></div>")
                }
                setTimeout(function() {
                    window.$('#alert-wrap div:first-child').remove()
                }, 3000)
            }
        }

        Vue.prototype.httpError = function (data) {
            if(data.state === 401) {
                this.notify('error', data.data.message, false)

                this.$router.push({ name: 'Login' }).catch(() => {})
            } else {
                if(data.data.message !== '') {
                    this.notify('error', data.data.message, false)
                }

                for(let i=0; i<data.data.error.length; i++) {
                    if(data.data.error[i]) {
                        this.notify('error', data.data.error[i].message, false)
                    }
                }
            }
        }

        Vue.prototype.refreshTokenRate = 3*60*1000
        Vue.prototype.refreshToken = function () {
            let refreshToken = sessionStorage.getItem('refreshToken')
            if(!refreshToken) {
                return
            }

            let formData = new FormData()
            formData.append('refreshToken', refreshToken)

            this.$post(this.$ADMIN_REFRESH_TOKEN, 'refreshToken', formData, false, (result) => {
                sessionStorage.setItem('token', result.data[0].accessToken)
                sessionStorage.setItem('refreshToken', result.data[0].refreshToken)

                setTimeout(() => {
                    this.refreshToken()
                }, this.refreshTokenRate)
            }, (result) => {
                this.httpError(result)
                this.$router.push({ name: 'Login' }).catch(() => {})
            })
        }

        Vue.prototype.getFormatData = function (data, result) {
            for (const key of Object.keys(data)) {
                if(typeof result.data[key] === 'number') {
                    data[key] = result.data[key].toString()
                } else if(typeof result.data[key] === 'boolean') {
                    data[key] = result.data[key] ? true : false
                } else if(typeof result.data[key] === 'undefined') {
                    data[key] = ''
                } else if(result.data[key] === null) {
                    data[key] = ''
                } else {
                    data[key] = result.data[key]
                }
            }
        }

        Vue.prototype.$fileDownload = function (response){
            let filename = response.headers['content-disposition'].split('filename=')[1].split('"')[1]
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', filename)
            link.innerText = '다운로드'
            return [link, filename]
        }

        Vue.prototype.$downloadExcelData = function (tableId, filename) {
            if (filename == null || typeof filename == undefined)
                filename = tableId;
            filename += ".csv";

            let BOM = "\uFEFF";

            let table = document.getElementById(tableId);
            let csvString = BOM;
            for (let rowCnt = 0; rowCnt < table.rows.length; rowCnt++) {
                let rowData = table.rows[rowCnt].cells;
                for (let colCnt = 0; colCnt < rowData.length; colCnt++) {
                    let columnData = rowData[colCnt].textContent;
                    if (columnData == null || columnData.length === 0) {
                        if(colCnt === 0) {
                            continue
                        } else {
                            columnData = "".replace(/"/g, '""');
                        }
                    }
                    else {
                        columnData = columnData.toString().replace(/"/g, '""');
                    }
                    csvString = csvString + '"' + columnData + '",';
                }
                csvString = csvString.substring(0, csvString.length - 1);
                csvString = csvString + "\r\n";
            }
            csvString = csvString.substring(0, csvString.length - 1);

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                let blob = new Blob([decodeURIComponent(csvString)], {
                    type: 'text/csv;charset=utf8'
                });
                window.navigator.msSaveOrOpenBlob(blob, filename);
            } else if (window.Blob && window.URL) {
                let blob = new Blob([csvString], { type: 'text/csv;charset=utf8' });
                let csvUrl = URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', csvUrl);
                a.setAttribute('download', filename);
                document.body.appendChild(a);
                a.click()
                a.remove();
            } else {
                let csvData = 'data:application/csv;charset=utf-8,' + encodeURIComponent(csvString);
                let blob = new Blob([csvString], { type: 'text/csv;charset=utf8' });
                let csvUrl = URL.createObjectURL(blob);
                console.log(csvUrl)
                let a = document.createElement('a');
                a.setAttribute('style', 'display:none');
                a.setAttribute('target', '_blank');
                a.setAttribute('href', csvData);
                a.setAttribute('download', filename);
                document.body.appendChild(a);
                a.click()
                a.remove();
            }
        }

        Vue.prototype.$createTables = function () {
            this.$nextTick(() => {
                window.$("#dataTable").DataTable({
                    "responsive": true,
                    "lengthChange": true,
                    "autoWidth": false,
                    "searching": false,
                    "ordering": true,
                    "paging": false,
                    "buttons": [
                        "copy",
                        {
                            extend : 'csv',
                            charset : 'UTF-8',
                            bom : true,
                        },
                        "excel",
                        "pdf",
                        "print",
                        "colvis"
                    ]
                }).buttons().container().appendTo('#dataTableButtons')
            })
        }

        Vue.prototype.$destroyTables = function () {
            window.$("#dataTable").DataTable().destroy()
        }

        Vue.prototype.$getMaxDate = function (_startDate) {
            if (_startDate === '' || typeof _startDate === 'undefined') return ''

            let startDate = new Date(_startDate)
            let maxDate = this.$moment(startDate).add(3, 'month').format('YYYY-MM-DD')
            return maxDate
        }

        Vue.prototype.$watchStartDate = function (_startDate) {
            let startTmpDate = ''
            if(_startDate) {
                let date = new Date(_startDate)
                date.setHours(0)
                date.setMinutes(0)
                date.setSeconds(0)
                startTmpDate = +date
            } else {
                startTmpDate = ''
            }
            this.$getMaxDate(_startDate)
            return startTmpDate
        }

        Vue.prototype.$watchEndDate = function (_endDate) {
            let endTmpDate = ''
            if(_endDate) {
                let date = new Date(_endDate)
                date.setHours(23)
                date.setMinutes(59)
                date.setSeconds(59)
                endTmpDate = +date
            } else {
                endTmpDate = ''
            }
            return endTmpDate
        }

        Vue.prototype.$roundToDecimal = function (number, decimal) {
            if(number === '') number = 0
            return +(Math.round(`${number}` + `e+${decimal}`)  + `e-${decimal}`)
        }

        Vue.prototype.$numberWithCommasAndDecimal = function (x) {
            let decimalView = sessionStorage.getItem('decimalView')
            if(!decimalView) {
                decimalView = 9
            }
            let zeros = ''
            for(let i=0; i<decimalView; i++) {
                zeros += '0'
            }

            if(!(x+'').includes('.')) {
                return (x+'').replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.' + zeros
            } else {
                let parts = x.toString().split(".")
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                parts[1] = parts[1] + zeros
                parts[1] = parts[1].substr(0, decimalView)
                return parts.join('.')
            }
        }

        Vue.prototype.$dateFormat = function (date) {
            let dateFormat2 = date.getFullYear() + "-" + ((date.getMonth() + 1) > 9
                ? (date.getMonth() + 1).toString() : "0" + (date.getMonth() + 1)) +
              "-" + (date.getDate() > 9 ? date.getDate().toString() : "0" + date.getDate().toString());
            return dateFormat2
        }

        Vue.prototype.$setDecimal = function (n, decimal) {
            let result = Math.floor(n * decimal)/decimal
            return result
        }

        Vue.prototype.$numberWithCommas = function (x) {
            if (!(x + '').includes('.')) {
                return (x + '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            } else {
                return `${((x + '').split('.')[0]).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${(x + '').split('.')[1]}`
            }
        }
    }
})
