<template>
  <div style="padding: 20px">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">락업 플랜 등록</h4>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
              </div>
              <div class="card-body">
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>이름 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" placeholder="입력" v-model="formData.lockupPlanName"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>락업기간(일) *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="number" class="form-control" min="0" v-model="formData.lockupPlanPeriod"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>지급비율(%) *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="number" class="form-control" min="0" v-model="formData.lockupPlanRatio"/>
                  </div>
                  <div class="col-sm-3">
                    <span style="word-break: keep-all">균등 지급을 위해 지급비율을 100으로 나누었을 시 몫이 정수가 되는 값을 입력해주세요.</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>보상비율(%) *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="number" class="form-control" min="0" v-model="formData.lockupPlanRewardRatio"/>
                  </div>
                </div>
              </div>
              <div class="card-footer clearfix text-center row">
                <div class="col-sm-3">
                </div>
                <div class="col-sm-5">
                  <button type="button" class="btn btn-primary mr-1" @click="register"
                          :disabled="!formData.lockupPlanName||!formData.lockupPlanPeriod||!formData.lockupPlanRatio||formData.lockupPlanRewardRatio===''">
                    등록
                  </button>
                  <button type="button" class="btn btn-secondary" @click="$router.push({ name : 'LockupPlanList' })">
                    목록
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'LockupPlanRegister',
  data() {
    return {
      formData: {
        lockupPlanName: '',
        lockupPlanPeriod: 0,
        lockupPlanRatio: 0,
        lockupPlanRewardRatio: 0,
      },
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'lockupPlan', this.$options.name)
  },

  methods: {
    register() {
      if (this.formData.lockupPlanPeriod <= 0 || this.formData.lockupPlanRatio <= 0 || this.formData.lockupPlanRewardRatio < 0) {
        this.notify('error', '양수만 입력 가능합니다.')
        return
      }
      if (100 % this.formData.lockupPlanRatio) {
        this.notify('error', '지급비율을 다시 입력해주세요.')
        return
      }

      let formData = new FormData()

      let stop = false
      Object.entries(this.formData).forEach((entry) => {
        let [key, value] = entry
        if (!stop && value === '') {
          this.notify('error', '정확히 입력해주세요.')
          stop = true
          return
        }
        formData.append(key, key === 'lockupPlanRatio' ? (100 / this.formData.lockupPlanRatio) : (value + '').trim())
      })

      if (stop) {
        return
      }

      this.$post(this.$LOCKUP_PLAN_REGISTER, this.$options.name + '_setData', formData, true, () => {
        this.notify('success', '등록되었습니다.')
        this.formData = {
          lockupPlanName: '',
          lockupPlanPeriod: 0,
          lockupPlanRatio: 0,
          lockupPlanRewardRatio: 0,
        }
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style>
</style>
