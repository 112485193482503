<template>
  <div>
    <div style="padding: 20px">
      <Confirm ref="Confirm"></Confirm>
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-4">
            <div class="col-sm-12">
              <h4 class="m-0">락업 플랜 상세</h4>
            </div>
          </div>
        </div>
      </div>
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                </div>
                <div class="card-body">
                  <div class="row align-items-center mb-2">
                    <div class="col-sm-3 text-right">
                      <strong>이름 *</strong>
                    </div>
                    <div class="col-sm-5">
                      <p class="form-control">{{ item.name }}</p>
                    </div>
                  </div>
                  <div class="row align-items-center mb-2">
                    <div class="col-sm-3 text-right">
                      <strong>락업기간 ( 일수 ) *</strong>
                    </div>
                    <div class="col-sm-5">
                      <p class="form-control">{{ item.period }}</p>
                    </div>
                  </div>
                  <div class="row align-items-center mb-2">
                    <div class="col-sm-3 text-right">
                      <strong>지급비율 ( % ) *</strong>
                    </div>
                    <div class="col-sm-5">
                      <p class="form-control">{{ $setDecimal(item.shareP, 10000) }}</p>
                    </div>
                  </div>
                  <div class="row align-items-center mb-2">
                    <div class="col-sm-3 text-right">
                      <strong>보상비율 ( % ) *</strong>
                    </div>
                    <div class="col-sm-5">
                      <p class="form-control">{{ $setDecimal(item.rewardP, 10000) }}</p>
                    </div>
                  </div>
                  <div class="row align-items-center mb-2">
                    <div class="col-sm-3 text-right">
                      <strong>생성일</strong>
                    </div>
                    <div class="col-sm-5">
                      <p class="form-control">{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</p>
                    </div>
                  </div>
                </div>
                <div class="card-footer clearfix text-center row">
                  <div class="col-sm-3">
                  </div>
                  <div class="col-sm-5">
                    <button type="button" class="btn btn-primary mr-1" @click="openConfirm">삭제</button>
                    <button type="button" class="btn btn-secondary" @click="$router.back()">목록</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Confirm from "@/components/common/Confirm";

export default {
  name: "LockupPlanDetail",

  components: {
    Confirm,
  },

  data() {
    return {
      item: '',
      lockupPlanIdx: this.$route.params.lockupPlanIdx,
    }
  },

  created() {
    if (!this.lockupPlanIdx) {
      this.$router.back()
      return
    }

    this.getLockupPlanDetail()
  },

  methods: {
    openConfirm() {
      this.$refs.Confirm.showDialog(`${this.item.name}을(를) 삭제하시겠습니까?`, () => {
        this.deletePlan()
      })
    },

    deletePlan() {
      let formData = new FormData()
      formData.append('idx', this.lockupPlanIdx)
      this.$post(this.$LOCKUP_PLAN_DELETE, 'delete_lockup_plan', formData, true, () => {
        alert('플랜 삭제가 완료되었습니다.')
        this.$router.replace({name: 'LockupPlanList'})
      }, (result) => {
        this.httpError(result)
      })
    },

    getLockupPlanDetail() {
      this.$get(`${this.$LOCKUP_PLAN}/${this.lockupPlanIdx}`, 'get_lockup_Plan_detail', true, (result) => {
        this.item = result.data[0]
      }, (result) => {
        this.httpError(result)
      })
    }
  }
}
</script>

<style scoped>

</style>