<template>
  <transition name="modal" v-if="show">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="content-header">
            <div class="container-fluid">
              <div class="row mb-3 justify-content-end" @click="closePopup()">
                <i class="fas fa-times close-modal-btn" style="cursor: pointer; font-size: 25px"></i>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="row justify-content-start">
                    <div class="row">
                      <h5 class="ml-3 font-weight-bold">락업 상세 내역</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section class="content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <table class="table table-bordered" id="excelData">
                        <thead>
                        <tr>
                          <th>No</th>
                          <th>신청일</th>
                          <th>지급일</th>
                          <th>구분</th>
                          <th>수량</th>
                        </tr>
                        </thead>
                        <tbody v-if="items.length > 0">
                        <tr v-for="(item, index) in items" :key="index">
                          <td style="text-align: center">{{ index + 1 + (page - 1) * 10 }}</td>
                          <td class="text-center">
                            {{ item.createdAt | moment('YYYY-MM-DD') }}
                          </td>
                          <td class="text-center">
                            {{ item.updatedAt | moment('YYYY-MM-DD') }}
                          </td>
                          <td class="text-center" :class="{'text-danger': item.lockUpHistoryType === 'PAID'}">
                            {{ trans(item.lockUpHistoryType) }}
                          </td>
                          <td class="text-right">{{ $numberWithCommasAndDecimal(item.amount) }}</td>
                        </tr>
                        </tbody>
                        <tbody v-else>
                        <tr>
                          <td colspan="10" style="text-align: center;">관련된 내역이 없습니다.</td>
                        </tr>
                        </tbody>
                      </table>
                      <div class="row justify-content-center mt-3">
                        <div></div>
                        <div>
                          <ul class="pagination pagination-sm m-0">
                            <li class="page-item" v-bind:class="{ disabled: prevTab === 0 }">
                              <button class="page-link" @click="changePage(prevTab)">&laquo;</button>
                            </li>
                            <li class="page-item" v-bind:class="{ disabled: prev === 0 }">
                              <button class="page-link" @click="changePage(prev)">&lt;</button>
                            </li>
                            <li class="page-item" v-for="n in paging" v-bind:key="n" v-bind:class="{ active: n === page }">
                              <button class="page-link" @click="changePage(n)">{{ n }}</button>
                            </li>
                            <li class="page-item" v-bind:class="{ disabled: next === 0 }">
                              <button class="page-link" @click="changePage(next)">&gt;</button>
                            </li>
                            <li class="page-item" v-bind:class="{ disabled: nextTab === 0 }">
                              <button class="page-link" @click="changePage(nextTab)">&raquo;</button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "LockupCancelList",

  data() {
    return {
      show: false,
      page: 1,
      size: '10',
      total: 0,
      items: [],
      idx: '',
      prevTab: 0,
      prev: 0,
      next: 0,
      nextTab: 0,
      tab: 10,
      paging: [],
      totalPage: 0,
    }
  },

  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'lockupPlan', 'LockupUser')
  },

  methods: {
    changePage(page) {
      this.page = page
    },

    initPage() {
      this.totalPage = Math.floor((this.total - 1) / this.size) + 1

      this.next = this.page + 1
      if (this.next > this.totalPage) {
        this.next = 0
      }

      this.prev = this.page - 1
      if (this.prev < 0) {
        this.prev = 0
      }

      this.nextTab = this.page + this.tab
      if (this.nextTab > this.totalPage) {
        this.nextTab = 0
      }

      this.prevTab = this.page - this.tab
      if (this.prevTab < 0) {
        this.prevTab = 0
      }

      this.paging = []

      let start = Math.floor((this.page - 1) / 10) * 10 + 1
      let end = start + this.tab - 1
      if (end > this.totalPage) {
        end = this.totalPage
      }

      for (let i = start; i <= end; i++) {
        this.paging.push(i)
      }
    },

    trans(value) {
      if (value === 'PART') {
        return '해지'
      } else if (value === 'PAID') {
        return '지급'
      } else if (value === 'REGISTER') {
        return '등록'
      } else if (value === 'CANCEL') {
        return '종료'
      } else if (value === 'SUSPEND') {
        return '중단'
      }
    },

    getList(page) {
      let params = new URLSearchParams()
      params.append('page', page)
      params.append('limit', this.size)
      this.$get(`${this.$LOCKUP_HISTORY}/${this.idx}?` + params.toString(), this.$options.name + '_getList', true, (result) => {
        this.page = page
        this.total = result.total
        this.items = result.data
        this.initPage()
        this.show = true
      }, (result) => {
        this.httpError(result)
      })
    },

    closePopup() {
      this.show = false
      this.$emit('closePopup')
    },

    showPopup(idx) {
      this.idx = idx
      this.getList(1)
    },
  },
  watch: {
    page(){
      this.getList(this.page)
    }
  }
}
</script>

<style scoped>
th, td {
  padding: 8px;
  font-size: 16px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  padding: 100px;
}

.modal-container {
  width: 90vw;
  height: 80vh;
  margin: 0px auto;
  padding: 5px 30px 30px 30px;
  background-color: #535c66;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>