<template>
  <div style="padding: 20px">
    <Confirm ref="Confirm"></Confirm>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">스테이킹 플랜 상세</h4>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
              </div>
              <div class="card-body">
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>구분</strong>
                  </div>
                  <div class="col-sm-5">
                    <span class="form-control" v-if="item.type === 'FIX'" style="background: #6c757d;">고정이자</span>
                    <span class="form-control" v-if="item.type === 'EXTRA'" style="background: #6c757d;">추가보상</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>이름</strong>
                  </div>
                  <div class="col-sm-5">
                    <span class="form-control" style="background: #6c757d;">{{ item.stakingPlanName }}</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2" v-if="item.type === 'EXTRA'">
                  <div class="col-sm-3 text-right">
                    <strong><span>스테이킹</span><br>참여시작</strong>
                  </div>
                  <div class="col-sm-2">
                    <span class="form-control"
                          style="background: #6c757d;">{{ item.participateStartDate | moment('YYYY-MM-DD') }}</span>
                  </div>
                  <div class="col-sm-1 text-right">
                    <strong><span>스테이킹</span><br>참여종료</strong>
                  </div>
                  <div class="col-sm-2">
                    <span class="form-control"
                          style="background: #6c757d;">{{ item.participateEndDate | moment('YYYY-MM-DD') }}</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>스테이킹 시작</strong>
                  </div>
                  <div class="col-sm-2">
                    <span class="form-control"
                          style="background: #6c757d;">{{ item.stakingStartDate | moment('YYYY-MM-DD') }}</span>
                  </div>
                  <div class="col-sm-1 text-right">
                    <strong>스테이킹 종료</strong>
                  </div>
                  <div class="col-sm-2">
                    <span class="form-control"
                          style="background: #6c757d;">{{ item.stakingExpiryDate | moment('YYYY-MM-DD') }}</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>최소수량</strong>
                  </div>
                  <div class="col-sm-2">
                    <span class="form-control" style="background: #6c757d;">
                      {{ $numberWithCommas(item.minQuantity) }}
                    </span>
                  </div>
                  <div class="col-sm-1 text-right">
                    <strong>최대수량</strong>
                  </div>
                  <div class="col-sm-2">
                    <span class="form-control" style="background: #6c757d;">
                      {{ $numberWithCommas(item.maxQuantity) }}
                    </span>
                  </div>
                </div>
                <div class="row align-items-center mb-2" v-if="item.type === 'FIX'">
                  <div class="col-sm-3 text-right">
                    <strong>보상비율</strong>
                  </div>
                  <div class="col-sm-2" v-if="item.type === 'FIX'">
                    <span class="form-control" style="background: #6c757d;">
                      {{ $setDecimal(item.rewardPoint, 10) }} %
                    </span>
                  </div>
                  <div class="col-sm-1 text-right">
                    <strong>일 보상비율</strong>
                  </div>
                  <div class="col-sm-2">
                    <span class="form-control" style="background: #6c757d;">{{ dayRewardPoint }} %</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2" v-if="item.type === 'EXTRA'">
                  <div class="col-sm-3 text-right">
                    <strong>총보상금액(원) *</strong>
                  </div>
                  <div class="col-sm-5" v-if="item.type === 'EXTRA'">
                    <input type="number" class="form-control" v-model="item.rewardPoint"/>
                    <div class="input_placeholder">
                      <span>총보상금액은 추후 입력 및 수정이 가능합니다.</span>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>중복참여</strong>
                  </div>
                  <div class="col-sm-5">
                    <span class="form-control" v-if="item.participateDuplicate"
                          style="background: #6c757d;">중복참여 가능</span>
                    <span class="form-control" v-else style="background: #6c757d;">중복참여 불가능</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>내용</strong>
                  </div>
                  <div class="col-sm-5">
                    <textarea class="form-control" rows="8" maxlength="300" v-model="item.description"
                              v-if="item.type === 'FIX'" disabled style="background: #6c757d;"></textarea>
                    <textarea class="form-control" rows="8" maxlength="300" v-model="item.description"
                              v-if="item.type === 'EXTRA'"></textarea>
                  </div>
                </div>
              </div>
              <div class="card-footer clearfix text-center row">
                <div class="col-sm-3">
                </div>
                <div class="col-sm-5">
                  <button type="button" class="btn btn-primary mr-1" v-if="item.type === 'EXTRA'" @click="planModify()"
                          :disabled="item.rewardPoint === ''">수정
                  </button>
                  <button type="button" class="btn btn-primary mr-1" @click="openConfirm()">삭제</button>
                  <button type="button" class="btn btn-secondary" @click="$router.back()">목록</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Confirm from "@/components/common/Confirm";

export default {
  name: "StakingPlanDetail",

  components: {
    Confirm,
  },

  data() {
    return {
      planId: this.$route.params.planId,
      item: [],
      dayRewardPoint: ''
    }
  },

  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'stakingPlan', this.$options.name)
    this.getStakingDetail()
  },

  methods: {
    getDateDiff(d1, d2) {
      const date1 = new Date(d1)
      const date2 = new Date(d2)
      const diffDate = date1.getTime() - date2.getTime();
      const dateResult = Math.floor(Math.abs(diffDate / (1000 * 60 * 60 * 24)) + 1)
      const result = Math.ceil(this.item.rewardPoint / dateResult * 10000) / 10000
      this.dayRewardPoint = result
    },

    openConfirm() {
      this.$refs.Confirm.showDialog(`${this.item.stakingPlanName}을(를) 삭제하시겠습니까?`, () => {
        this.planDelete()
      })
    },

    planDelete() {
      this.$delete(`${this.$STAKING_PLAN}/${this.item.stakingPlanIdx}`, this.$options.name + '_deleteData', true, () => {
        this.notify('success', '스테이킹 플랜 삭제가 완료되었습니다.')
        this.$router.back()
      }, (result) => {
        this.httpError(result)
      })
    },

    planModify() {
      if (this.item.rewardPoint < 0) {
        this.notify('error', '총보상금액은 양수만 입력 가능합니다.')
        return
      }
      this.$refs.Confirm.showDialog(`${this.item.stakingPlanName}을(를) 수정하시겠습니까?`, () => {
        let formData = new FormData()
        formData.append('planIdx', this.item.stakingPlanIdx)
        formData.append('rewardPoint', this.item.rewardPoint)
        formData.append('description', this.item.description)
        this.$put(this.$STAKING_PLAN, this.$options.name + '_modifyData', formData, true, () => {
          this.notify('success', '스테이킹 플랜 수정이 완료되었습니다.')
        }, (result) => {
          this.httpError(result)
        })
      })
    },

    getStakingDetail() {
      this.$get(`${this.$STAKING_PLAN}/${this.planId}`, this.$options.name + '_getData', true, (result) => {
        this.item = result.data[0]
        if (this.item) {
          this.getDateDiff(this.item.stakingStartDate, this.item.stakingExpiryDate)
        }
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style scoped>
</style>