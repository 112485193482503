<template>
  <div style="padding: 20px">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">출금 화이트리스트</h4>
            <span>사용자 출금 화이트 리스트</span>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th>NO</th>
                    <th>주소</th>
                    <th>출금횟수</th>
                    <th>액션</th>
                  </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                  <tr v-for="(item, index) in items" :key="item.idx">
                    <td style="text-align: center">{{ index + 1 + (page - 1) * 10 }}</td>
                    <td>{{ item.address }}</td>
                    <td style="text-align: right">{{ item.count }}</td>
                    <td class="text-center">
                      <div class="btn-group mr-2">
                        <button class="badge btn btn-success mr-1" :class="{ disabled: item.status }"
                                @click="!item.status ? setData(item.idx, true): ''">승인
                        </button>
                        <button class="badge btn btn-danger" :class="{ disabled: !item.status }"
                                @click="item.status ? setData(item.idx, false) : ''">미승인
                        </button>
                      </div>
                      <span class="badge btn btn-outline-danger" @click="deleteData(item.idx)">삭제</span>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td colspan="6" style="text-align: center;">관련된 내역이 없습니다.</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer clearfix text-center row justify-content-center">
                <pagination ref="Pagination"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "../common/Pagination"

export default {
  name: "WhiteList",

  components: {
    Pagination,
  },

  data() {
    return {
      size: '10',
      page: 0,
      total: 0,
      items: []
    }
  },

  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'user', this.$options.name)

    let page = this.$route.query.page
    if (typeof (page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if (typeof (size) !== 'undefined') {
      this.size = size
    }

    this.getList(page)
  },

  methods: {
    getList(page) {
      let params = new URLSearchParams()
      if (typeof page === 'undefined') page = 1
      params.append('page', page)
      params.append('limit', this.size)

      this.$get(this.$TRANSFER_WHITE + '?' + params.toString(), this.$options.name + '_getList', true, (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {}, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }
        this.items = result.data
      }, (result) => {
        console.log(result)
      })
    },

    setData(idx, status) {
      let formData = new FormData()
      formData.append('idx', idx)
      formData.append('status', status)

      this.$post(this.$TRANSFER_WHITE, this.$options.name + '_setData', formData, true, (result) => {
        this.getList(this.page)
        this.notify('success', result.message)
      }, (result) => {
        console.log(result)
      })
    },

    deleteData(idx) {
      let formData = new FormData()
      formData.append('idx', idx)

      this.$post(this.$TRANSFER_WHITE_DELETE, this.$options.name + '_deleteData', formData, true, (result) => {
        this.getList(this.page)

        this.notify('success', result.message)
      }, (result) => {
        console.log(result)
      })
    },
  },

  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    }
  },
}
</script>

<style scoped>

</style>