<template>
  <div style="padding: 20px;">
    <Confirm ref="Confirm"></Confirm>
    <LockupDelayList ref="DelayListPopup" @closePopup=closePopup></LockupDelayList>
    <LockupCancelList ref="CancelListPopup" @closePopup=closePopup></LockupCancelList>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="mb-2">락업 참여자</h4>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="row justify-content-between">
              <div class="row">
                <h5 class="mr-2 mt-sm-2 ml-2">필터</h5>
                <select class="form-control w-auto mr-1" v-model="selectedSearchType">
                  <option value="LOCKUP_PLAN_NAME">락업 플랜</option>
                  <option value="LOCKUP_USER_ID">아이디</option>
                </select>
                <input type="text" class="form-control w-auto mr-1" placeholder="검색어" v-model="searchKeyword"
                       @keyup.enter="searchStateBtn(selectedState)">
                <button class="btn btn-primary" @click="searchStateBtn(selectedState)">검색</button>
              </div>
              <div>
                <button class="btn btn-primary btn-sm mr-2 pl-3 pr-3" @click="openDelayList()">지급지연 내역</button>
                <button class="badge btn btn-default text-white p-2 pr-3 pl-3 border-secondary" style="border-radius: 0"
                        :class="{'btn-warning': selectedState === 'ALL'}" @click="searchStateBtn('ALL')">
                  전체
                </button>
                <button class="badge btn btn-default text-white p-2 pr-3 pl-3 border-secondary" style="border-radius: 0"
                        :class="{'btn-warning': selectedState === 'PROCESSING'}" @click="searchStateBtn( 'PROCESSING')">
                  지급중
                </button>
                <button class="badge btn btn-default text-white p-2 pr-3 pl-3 border-secondary" style="border-radius: 0"
                        :class="{'btn-warning': selectedState === 'LOCK'}" @click="searchStateBtn('LOCK')">
                  진행중
                </button>
                <button class="badge btn btn-default text-white p-2 pr-3 pl-3 border-secondary" style="border-radius: 0"
                        :class="{'btn-warning': selectedState === 'DONE'}" @click="searchStateBtn( 'DONE')">
                  종료
                </button>
                <button class="badge btn btn-default text-white p-2 pr-3 pl-3 border-secondary" style="border-radius: 0"
                        :class="{'btn-warning': selectedState === 'SUSPEND'}" @click="searchStateBtn('SUSPEND')">
                  지급중단
                </button>
                <button class="badge btn btn-default text-white p-2 pr-3 pl-3 border-secondary" style="border-radius: 0"
                        :class="{'btn-warning': selectedState === 'CANCEL'}" @click="searchStateBtn('CANCEL')">
                  락업취소
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>
                      <input type="checkbox" disabled v-if="!selectSuspend && !selectCancel && !selectCancelPart">
                      <input type="checkbox" v-model="checkedAllProcessing" v-if="selectSuspend">
                      <input type="checkbox" v-model="checkedAllLock" v-if="selectCancel">
                    </th>
                    <th>NO</th>
                    <th>락업 플랜</th>
                    <th>락업 기간</th>
                    <th>아이디</th>
                    <th>락업 잔고</th>
                    <th>지급 예정 잔고</th>
                    <th>지급 완료 잔고</th>
                    <th>보상 비율</th>
                    <th>지급 비율</th>
                    <th>상태</th>
                    <th>내역보기</th>
                  </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                  <tr v-for="(item, index) in items" :key="item.lockupIdx">
                    <td class="text-center">
                      <input type="checkbox" disabled v-if="!selectSuspend && !selectCancel && !selectCancelPart">
                      <input type="checkbox" v-if="selectSuspend && item.status === 'PROCESSING' && !selectCancelPart"
                             :id="'chk'+item.lockupIdx" v-model="suspendChk" :value="item.lockupIdx">
                      <input type="checkbox" disabled
                             v-if="selectSuspend && item.status !== 'PROCESSING' && !selectCancelPart">
                      <input type="checkbox" v-if="selectCancel && item.status === 'LOCK'" :id="'chk'+item.lockupIdx"
                             v-model="cancelChk" :value="item.lockupIdx">
                      <input type="checkbox" disabled v-if="selectCancel && item.status !== 'LOCK'">
                      <input type="radio" v-if="selectCancelPart && item.status === 'LOCK'" v-model="cancelPartChk"
                             :value="item.lockupIdx">
                      <input type="radio" disabled v-if="selectCancelPart && item.status !== 'LOCK'">
                    </td>
                    <td class="text-center">{{ index + 1 + (page - 1) * 10 }}</td>
                    <td>{{ item.lockupPlanName }}</td>
                    <td class="text-center">{{ item.lockupStartDate | moment('YYYY-MM-DD') }} ~
                      {{ item.lockupExpiryDate | moment('YYYY-MM-DD') }}
                    </td>
                    <td><span @click="$router.push({ name : 'UserDetail', params: { userIdx: item.userIdx }})"
                              style="text-decoration: underline; cursor: pointer;">{{ item.userId }}</span></td>
                    <td class="text-right">{{ $numberWithCommasAndDecimal(item.lockupAmount) }}</td>
                    <td class="text-right">{{ $numberWithCommasAndDecimal(item.remainAmount) }}</td>
                    <td class="text-right">{{ $numberWithCommasAndDecimal(item.availableBalance) }}</td>
                    <td class="text-right">{{ $setDecimal(item.rewardRate, 10000) }} %</td>
                    <td class="text-right">{{ $setDecimal(item.lockupRatio, 10000) }} %</td>
                    <td class="text-center"
                        :class="{ 'text-orange':item.status === 'LOCK' || item.status === 'PROCESSING'}">
                      {{ trans(item.status) }}
                    </td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-light" @click="openCancelList(item.lockupIdx)">내역보기</span>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td colspan="15" style="text-align: center;">관련된 내역이 없습니다.</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="row justify-content-end mr-3 mb-3">
                <button class="btn btn-sm btn-secondary text-white mr-2" v-if="!selectCancelPart"
                        @click="selectCancelPart = true; selectCancel = false; selectSuspend = false;">
                  부분 해지 선택
                </button>
                <button class="btn btn-sm btn-warning text-white mr-2" v-else @click="openClosePartPopup()">
                  부분 해지
                </button>
                <button class="btn btn-sm btn-secondary text-white mr-2" v-if="!selectSuspend"
                        @click="selectSuspend = true; selectCancel = false; selectCancelPart = false;">
                  지급 중단 선택
                </button>
                <button class="btn btn-sm btn-warning text-white mr-2" v-else @click="setLockupSuspend()">
                  지급 중단
                </button>
                <button class="btn btn-sm btn-secondary text-white mr-2" v-if="!selectCancel"
                        @click="selectCancel = true; selectSuspend = false; selectCancelPart = false;">
                  락업 종료 선택
                </button>
                <button class="btn btn-sm btn-warning text-white mr-2" v-else @click="setLockupClose()">
                  락업 종료
                </button>
              </div>
              <div class="card-footer clearfix text-center row justify-content-center">
                <div></div>
                <pagination ref="Pagination"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <transition name="modal" v-if="listState">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <h3 slot="header">
                <i class="fas fa-times close-modal-btn" style="cursor: pointer;" @click="listState=false"></i>
              </h3>
            </div>
            <div class="modal-body">
              <div>
                <h4>요청 성공 : </h4>
                <span v-for="(list, index) in successList" :key="index" style="margin-right: 10px">
                  {{ list.lockupPlanName }}({{ list.userId }})
                <span v-if="index+1 < successList.length"> / </span></span>
              </div>
              <div class="pt-4" v-if="failList.length > 0">
                <h4>요청 실패 : </h4>
                <span v-for="(list, index) in failList" :key="index" style="margin-right: 10px">
                  {{ list.lockupPlanName }}({{ list.userId }})
                <span v-if="index+1 < failList.length"> / </span></span>
              </div>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button mr-2" @click="listState=false">
                  확인
                </button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="modal" v-if="cancelPartPopup">
      <div class="modal-mask" style="z-index: 99">
        <div class="modal-wrapper">
          <div class="modal-container cancelPopup">
            <div class="modal-header">
              <h3 slot="header">
                <i class="fas fa-times close-modal-btn" style="cursor: pointer;" @click="cancelPartPopup=false"></i>
              </h3>
            </div>
            <div class="modal-body m-0 p-0 row flex-column align-items-center flex-nowrap">
              <h4 class="font-weight-bold mb-4">BUFF 락업 부분 해지</h4>
              <div class="popupInput">
                <span class="text-md mr-2">락업 수량</span>
                <input type="number" v-model="cancelAmount" disabled>
              </div>
              <div class="popupInput">
                <span class="text-md mr-2">해지 수량</span>
                <input type="number" v-model="amount" min="0" placeholder="수량 입력" @input="handleInput">
              </div>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button mr-2" @click="cancelPartPopup=false">
                  취소
                </button>
                <button class="modal-default-button btn-primary mr-2" @click="setLockupClosePart()">
                  부분해지
                </button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Pagination from "../common/Pagination";
import Confirm from "@/components/common/Confirm";
import LockupDelayList from "@/components/lockupPlan/LockupDelayList";
import LockupCancelList from "@/components/lockupPlan/LockupCancelList";

export default {
  name: 'LockupUser',

  components: {
    LockupCancelList,
    Pagination,
    Confirm,
    LockupDelayList,
  },

  data() {
    return {
      selectedState: this.$route.params.ss ? this.$route.params.ss : 'ALL',
      selectedSearchType: this.$route.params.st ? this.$route.params.st : 'LOCKUP_USER_ID',
      searchKeyword: this.$route.params.sk ? this.$route.params.sk : '',
      size: '10',
      page: this.$route.params.page ? this.$route.params.page : 1,
      total: 0,
      items: [],
      selectCancel: false,
      selectCancelPart: false,
      selectSuspend: false,
      cancelChk: [],
      cancelPartChk: '',
      suspendChk: [],
      processingList: [],
      lockList: [],
      listState: false,
      successList: [],
      failList: [],
      cancelPartPopup: false,
      cancelAmount: 0,
      amount: '',
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'lockupPlan', this.$options.name)
  },

  mounted() {
    let page = this.$route.query.page
    if (typeof (page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if (typeof (size) !== 'undefined') {
      this.size = size
    }

    let ss = this.$route.query.ss
    if (typeof (ss) !== 'undefined') {
      this.selectedState = ss
    }

    let st = this.$route.query.st
    if (typeof (st) !== 'undefined') {
      this.selectedSearchType = st
    }

    let sk = this.$route.query.sk
    if (typeof (sk) !== 'undefined') {
      this.searchKeyword = sk
    }

    this.getList(page, this.$route.query.ss)
  },

  methods: {
    handleInput(e) {
      let stringValue = e.target.value.toString()
      let regex = /^\d*(\.\d{1,4})?$/
      if (!stringValue.match(regex) && this.amount !== '') {
        this.amount = this.$setDecimal(this.amount, 10000)
      }
    },

    closePopup() {
      this.isPopupOpen = false
    },

    openDelayList() {
      this.isPopupOpen = true
      this.$refs.DelayListPopup.showPopup()
    },

    openCancelList(idx) {
      this.isPopupOpen = true
      this.$refs.CancelListPopup.showPopup(idx)
    },

    searchStateBtn(state) {
      this.selectedState = state
      this.getList(1, state)
    },

    openConfirm(type) {
      this.$refs.Confirm.showDialog(`락업수량: ${this.amtToLockup} / 락업지급수량: ${this.shareAmount}<br/>보상수량: ${this.amtToReward} / 보상지급수량: ${this.rewardShareAmount}`, () => {
        this.setLockupStatus(type)
      })
    },

    openClosePartPopup() {
      if (this.cancelPartChk.length === 0) {
        this.selectCancelPart = false
      } else {
        let findData = this.items.find(e => e.lockupIdx === this.cancelPartChk)
        this.cancelAmount = this.$setDecimal(findData.lockupAmount, 10000).toFixed(4)
        this.cancelPartPopup = true
      }
    },

    setLockupClosePart() {
      if (this.amount <= 0) {
        this.notify('error', '해지 수량은 양수만 입력 가능합니다.')
        return
      }
      if (this.amount >= parseFloat(this.cancelAmount)) {
        this.notify('error', '부분 해지 수량은 락업 수량보다 적게만 입력 가능합니다.')
        return
      }
      let formData = new FormData();
      let findData = this.items.find(e => e.lockupIdx === this.cancelPartChk)
      this.$refs.Confirm.showDialog(`${findData.userId}의 락입을 ${this.amount}BUFF 부분해지 하시겠습니까?<br>락업 부분해지 시 복구가 불가능합니다.`, () => {
        formData.append('lockupIdx', this.cancelPartChk)
        formData.append('cancelAmount', this.amount)
        this.$post(this.$LOCKUP_PLAN_CANCEL_PART, 'lockup_cancel', formData, true, () => {
          this.selectCancelPart = false
          this.cancelPartPopup = false
          this.cancelPartChk = []
          this.getList(this.$route.query.page, this.$route.query.ss)
          this.notify('success', '락업이 부분해지 되었습니다.')
        }, (result) => {
          this.httpError(result)
        })
      }, '락업을 부분해지 하겠습니다.')
    },

    setLockupSuspend() {
      this.successList = []
      this.failList = []
      let formData = new FormData();
      if (this.suspendChk.length === 0) {
        this.selectSuspend = false
      } else {
        this.$refs.Confirm.showDialog(`총 ${this.suspendChk.length}명의 락업 지급을 중단 하시겠습니까?<br>지급 중단 시 복구가 불가능합니다.`, () => {
          this.suspendChk.forEach((item) => {
            formData.append('lockupIdxList[]', item)
          })
          this.$post(this.$LOCKUP_PLAN_SUSPEND, 'lockup_suspend', formData, true, (result) => {
            this.selectSuspend = false
            this.suspendChk = []
            let resultData = result.data
            for (let i = 0; i < resultData.length; i++) {
              if (resultData[i].result === 'success') {
                let findData = this.items.find(e => e.lockupIdx === resultData[i].lockUpIdx)
                this.successList.push(findData)
              } else {
                let findData2 = this.items.find(e => e.lockupIdx === resultData[i].lockUpIdx)
                this.failList.push(findData2)
              }
            }
            this.listState = true
            this.getList(this.$route.query.page, this.$route.query.ss)
            this.notify('success', '락업 지급이 중단되었습니다.')
          }, (result) => {
            this.httpError(result)
          })
        }, '락업 지급을 중단하겠습니다.')
      }
    },

    setLockupClose() {
      this.successList = []
      this.failList = []
      let formData = new FormData();
      if (this.cancelChk.length === 0) {
        this.selectCancel = false
      } else {
        this.$refs.Confirm.showDialog(`총 ${this.cancelChk.length}명의 락업을 종료 하시겠습니까?<br>락업 종료 시 복구가 불가능합니다.`, () => {
          this.cancelChk.forEach((item) => {
            formData.append('lockupIdxList[]', item)
          })
          this.$post(this.$LOCKUP_PLAN_CANCEL, 'lockup_cancel', formData, true, (result) => {
            this.selectCancel = false
            this.cancelChk = []
            let resultData = result.data
            for (let i = 0; i < resultData.length; i++) {
              if (resultData[i].result === 'success') {
                let findData = this.items.find(e => e.lockupIdx === resultData[i].lockUpIdx)
                this.successList.push(findData)
              } else {
                let findData2 = this.items.find(e => e.lockupIdx === resultData[i].lockUpIdx)
                this.failList.push(findData2)
              }
            }
            this.listState = true
            this.getList(this.$route.query.page, this.$route.query.ss)
            this.notify('success', '락업이 종료되었습니다.')
          }, (result) => {
            this.httpError(result)
          })
        }, '락업을 종료하겠습니다.')
      }
    },

    addUserLockupPlan(item) {
      this.$router.push({name: 'UserAddLockupPlan', params: {userIdx: item.userIdx}})
    },

    trans(value) {
      if (value === 'LOCK') {
        return '진행중'
      } else if (value === 'PROCESSING') {
        return '지급중'
      } else if (value === 'CANCEL') {
        return '락업취소'
      } else if (value === 'DONE') {
        return '종료'
      } else if (value === 'SUSPEND') {
        return '지급중단'
      }
    },

    getList(page, state) {
      this.selectCancel = false
      this.selectSuspend = false
      this.cancelChk = []
      this.suspendChk = []
      let params = new URLSearchParams()
      if (typeof page === 'undefined') page = 1
      if (page === 1) this.page = 1
      params.append('page', page)
      params.append('limit', this.size)
      if (state) {
        if (state === 'ALL') {
          this.selectedState = state
        } else {
          this.selectedState = state
          params.append('state', this.selectedState)
        }
      }
      params.append('criteria', this.selectedSearchType)
      params.append('keyword', this.searchKeyword.trim())

      this.$get(this.$LOCKUP_HISTORY + '?' + params.toString(), this.$options.name + '_getList', true, (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {
            ss: this.selectedState,
            st: this.selectedSearchType,
            sk: this.searchKeyword,
          }, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }
        this.$router.replace({
          query: {
            ss: this.selectedState,
            st: this.selectedSearchType,
            sk: this.searchKeyword,
            page: this.page,
            size: this.size,
          },
        });
        this.items = result.data
        this.processingList = []
        this.lockList = []
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].status === 'PROCESSING') {
            this.processingList.push(this.items[i].lockupIdx)
          } else if (this.items[i].status === 'LOCK') {
            this.lockList.push(this.items[i].lockupIdx)
          }
        }
        this.items.forEach(item => {
          item.availableBalance = Number(item.lockupAmount) - Number(item.remainAmount)
          let ratio = item.lockupRatio
          item.lockupRatio = 100 / ratio
        })
      }, (result) => {
        this.httpError(result)
      })
    },
  },

  computed: {
    checkedAllProcessing: {
      get: function () {
        if (this.suspendChk.length <= 0) {
          return
        }
        return this.processingList.length === this.suspendChk.length;
      },
      set: function (e) {
        this.suspendChk = e ? this.processingList : [];
      },
    },

    checkedAllLock: {
      get: function () {
        if (this.cancelChk.length <= 0) {
          return
        }
        return this.lockList.length === this.cancelChk.length;
      },
      set: function (e) {
        this.cancelChk = e ? this.lockList : [];
      },
    },
  },

  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page, this.$route.query.ss)
      this.suspendChk = []
      this.cancelChk = []
    },

    'selectedState'() {
      this.page = 1
    },

    'selectedSearchType'() {
      this.page = 1
    },

    'searchKeyword'() {
      this.page = 1
    },

  },
}
</script>

<style>
.popupInput {
  margin: 10px;
}

.popupInput input {
  width: 250px;
  border: 1px solid #eee;
  border-radius: 20px;
  padding: 8px 10px;
  text-align: right;
  font-size: 16px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 60vw;
  height: 50vh;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #d2d2d2;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.cancelPopup {
  width: 500px;
  height: 355px;
}

.modal-header {
  border: none;
  padding: 0px;
  justify-content: end;
}

.modal-footer {
  border: none;
  justify-content: center;
}

.modal-body {
  margin: 20px;
  word-wrap: break-word;
  word-break: keep-all;
  font-size: 1.2rem;
  border: none;
  height: 60%;
}

.modal-default-button {
  text-align: center;
  border: none;
  border-radius: 5px;
  background: #b2b2b2;
  color: white;
  margin: 0px;
  padding: 10px;
  width: 200px;
}

@media (max-width: 240px) {
  .modal-body {
    margin: 0px;
  }

  .modal-default-button {
    text-align: center;
    border: none;
    border-radius: 5px;
    background: lightgray;
    color: white;
    margin: 0px;
    width: 50px;
    height: 30px;
    padding: 0px;
  }
}

.modal-default-button:hover {
  transition: all ease-in 0.1s 0s;
  background: gray;
  cursor: pointer;
}

.modal-default-button:active {
  position: relative;
  top: 2px;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-body,
.modal {
  color: #666 !important;
}
</style>
