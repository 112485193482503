<template>
  <div style="padding: 20px">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">화폐 출력 설정</h4>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
              </div>
              <div class="card-body">
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>표시 소수점 길이 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="number" class="form-control" v-model="formData.length"/>
                  </div>
                </div>
              </div>
              <div class="card-footer clearfix text-center row">
                <div class="col-sm-3">
                </div>
                <div class="col-sm-5">
                  <button type="button" class="btn btn-primary mr-1" @click="setLength">설정</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'CurrencyOutput',

  data() {
    return {
      formData: {
        length: 0,
      },
    }
  },

  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'currency', this.$options.name)
    let decimalView = sessionStorage.getItem('decimalView')
    if(decimalView) {
      this.formData.length = decimalView
    }
  },

  methods: {
    setLength() {
      let formData = new FormData()
      Object.entries(this.formData).forEach((entry) => {
        let [key, value] = entry
        formData.append(key, (value+'').trim())
      })

      this.$post(this.$LOCKUP_PLAN_FEE_DECIMAL, this.$options.name + '_setData', formData, true, () => {
        sessionStorage.setItem('decimalView', this.formData.length)
        this.notify('success', '설정되었습니다.')
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style>
</style>
