<template>
  <div style="padding: 20px">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">스테이킹 이용약관</h4>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
              </div>
              <div class="card-body">
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong class="text-white">약관</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.title" disabled
                           style="background: #6c757d;"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong class="text-white">개정날짜</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="date" class="form-control" v-model="formData.revisionDate" :min="date"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong class="text-white">내용</strong>
                  </div>
                  <div class="col-sm-5">
                    <editor
                        :initialValue="editorText"
                        :options="editorOptions"
                        height="500px"
                        initialEditType="wysiwyg"
                        previewStyle="vertical"
                        ref="contents"
                    />
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                  </div>
                  <div class="col-sm-5">
                    <label>
                      <input class="mr-1" type="checkbox" v-model="agree">
                      <span class="text-white"
                            style="font-weight: normal; font-size: 14px;">약관은 법규상 30일 뒤부터 등록이 가능합니다.</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="card-footer clearfix text-center row">
                <div class="col-sm-3">
                </div>
                <div class="col-sm-5">
                  <button type="button" class="btn btn-primary mr-1" @click="termsRegister"
                          :disabled="accessBtn">등록
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import '@toast-ui/editor/dist/toastui-editor.css';

import {Editor} from '@toast-ui/vue-editor';

export default {

  name: "TermsRegister",

  components: {
    editor: Editor
  },

  data() {
    return {
      formData: {
        title: 'BUFF 스테이킹 이용약관 개정 안내',
        revisionDate: '',
        contents: '',
      },
      agree: false,
      date: '',
      editorText: '',
      editorOptions: {
        hideModeSwitch: true
      },
    }
  },

  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'setting', this.$options.name)
    let d = new Date()
    let setDate = d.setDate(d.getDate() + 30)
    this.date = this.$dateFormat(new Date(setDate))
  },

  methods: {
    resetData() {
      this.formData = {
        title: 'BUFF 스테이킹 이용약관 개정 안내',
        revisionDate: '',
        contents: '',
      }
      this.agree = false
    },

    termsRegister() {
      let content = this.$refs.contents.invoke("getHTML");
      if (content === '<p><br></p>') {
        alert('내용을 입력해 주세요.')
        return
      }
      if (!this.agree) {
        this.notify('error', '약관 등록 규정에 동의하시면 체크해 주세요.')
        return;
      }
      this.formData.contents = content
      let formData = new FormData()
      let stop = false
      Object.entries(this.formData).forEach((entry) => {
        let [key, value] = entry
        if (!stop && value === '') {
          this.notify('error', '약관 등록 정보를 정확히 입력해주세요.')
          stop = true
          return
        }
        formData.append(key, value)
      })
      if (stop) {
        return
      }

      this.$post(this.$TERMS, this.$options.name + '_setData', formData, true, () => {
        this.notify('success', '약관이 등록되었습니다.')
        this.$router.push({name: 'TermsList'})
        this.resetData()
      }, (result) => {
        this.httpError(result)
      })
    },
  },

  computed: {
    accessBtn() {
      if (
          !this.formData.title
          || !this.formData.revisionDate
          || !this.agree
      ) {
        return true;
      }
      return false;
    },
  },
}
</script>

<style scoped>
input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-clear-button,
input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  color: transparent;
  cursor: pointer;
}

.dark-mode .card {
  color: black;
}
</style>