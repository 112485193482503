<template>
  <div style="padding: 20px;">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">내부 출금 설정</h4>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
              </div>
              <div class="card-body">
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>출금 최소 금액 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="number" class="form-control" v-model="formData.min"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>수수료 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="number" class="form-control" v-model="formData.fee"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>수수료 타입 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <select class="form-control" v-model="formData.feeType">
                      <option :value="true">퍼센트(%)</option>
                      <option :value="false">고정값(수량)</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="card-footer clearfix text-center row">
                <div class="col-sm-3">
                </div>
                <div class="col-sm-5">
                  <button type="button" class="btn btn-primary mr-1" @click="setFee">설정</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'CurrencyInternal',

  data() {
    return {
      formData: {
        fee: 0,
        feeType: 'true',
        min: 0,
      },
    }
  },

  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'currency', this.$options.name)
    this.$get(this.$LOCKUP_PLAN_MIN_INFO, this.$options.name + '_setData', true, (result) => {
      let data = result.data[0]
      this.formData.min = data.internalMin
    }, (result) => {
      this.httpError(result)
    })
    this.$get(this.$LOCKUP_PLAN_FEE_INFO, this.$options.name + '_setData', true, (result) => {
      let data = result.data[0]
      this.formData.fee = data.internalFee
      this.formData.feeType = data.internalFeeType.toString()
    }, (result) => {
      this.httpError(result)
    })
  },

  methods: {
    setMin() {
      let formData = new FormData()
      formData.append('min', this.formData.min)
      this.$post(this.$LOCKUP_PLAN_MIN_INTERNAL, this.$options.name + '_setData', formData, true, () => {
        this.notify('success', '설정되었습니다.')
      }, (result) => {
        this.httpError(result)
      })
    },

    setFee() {
      let formData = new FormData()
      Object.entries(this.formData).forEach((entry) => {
        let [key, value] = entry
        formData.append(key, (value + '').trim())
      })

      this.$post(this.$LOCKUP_PLAN_FEE_INTERNAL, this.$options.name + '_setData', formData, true, () => {
        this.setMin()
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style>
</style>
