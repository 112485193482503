<template>
  <div style="padding: 20px">
    <Confirm ref="Confirm"></Confirm>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">스테이킹 이용약관</h4>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
              </div>
              <div class="card-body">
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>약관</strong>
                  </div>
                  <div class="col-sm-5">
                    <p class="form-control" style="background: #6c757d;">{{ terms.title }}</p>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>개정날짜</strong>
                  </div>
                  <div class="col-sm-5">
                    <p class="form-control" style="background: #6c757d;">
                      {{ terms.revisionDate | moment('YYYY-MM-DD') }}
                    </p>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>내용</strong>
                  </div>
                  <div class="col-sm-5 form-control scrollBar" style="background: #6c757d;">
                    <viewer
                        :initialValue="viewerText"
                        height="500px"
                        v-html="terms.contents"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                  </div>
                </div>
              </div>
              <div class="card-footer clearfix text-center row">
                <div class="col-sm-3">
                </div>
                <div class="col-sm-5">
                  <button type="button" class="btn btn-secondary mr-1" @click="cancelReserve"
                          v-if="terms.state==='RESERVED'">예약취소
                  </button>
                  <button type="button" class="btn btn-primary mr-1" @click="$router.back()">목록</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Confirm from "@/components/common/Confirm";
import '@toast-ui/editor/dist/toastui-editor-viewer.css';

import {Viewer} from '@toast-ui/vue-editor';

export default {
  name: "TermsDetail",

  components: {
    Confirm,
    viewer: Viewer
  },

  data() {
    return {
      idx: this.$route.params.idx,
      terms: [],
      date: '',
      viewerText: ''
    }
  },

  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'setting', this.$options.name)
    this.getTermsDetail()
    let d = new Date()
    let setDate = d.setDate(d.getDate() + 30)
    this.date = this.$dateFormat(new Date(setDate))
  },

  methods: {
    cancelReserve() {
      this.$refs.Confirm.showDialog('예약 취소 시 새로 약관을 등록해야 하며 30일의 예약 기간은 필수입니다.', () => {
        this.$put(`${this.$TERMS}/${this.idx}`, this.$options.name + '_setData', null, true, () => {
          this.notify('success', '약관 예약이 취소되었습니다.')
          this.getTermsDetail()
        }, (result) => {
          this.httpError(result)
        })
      }, '예약 취소를 합니다.')
    },

    getTermsDetail() {
      this.$get(`${this.$TERMS}/${this.idx}`, this.$options.name + '_getData', true, (result) => {
        this.terms = result.data[0]
      }, (result) => {
        this.httpError(result)
      })
    },
  },
}
</script>

<style scoped>
.scrollBar {
  margin-left: 8px;
  height: 500px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
}

.form-control {
  max-width: 640px;
}

.scrollBar::-webkit-scrollbar {
  width: 10px;
}

.scrollBar::-webkit-scrollbar-thumb {
  height: 30%;
  background: rgb(206, 204, 204);

  border-radius: 10px;
}

.scrollBar::-webkit-scrollbar-track {
  background: rgba(107, 107, 107, 0.8);
}
</style>