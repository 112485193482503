<template>
  <div style="padding: 20px;">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">입출금 내역</h4>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 class="mb-2">필터</h5>
            <div class="row">
              <select class="form-control w-auto mr-1" v-model="selectedTransactionType">
                <option value="">입출금</option>
                <option value="true">입금</option>
                <option value="false">출금</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="selectedState">
                <option value="">진행상태</option>
                <option v-for="(state) in stateList" :key="state" :value="state">{{ transState(state) }}</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="selectedSearchType">
                <option value="">검색 타입</option>
                <option value="EMAIL">아이디</option>
                <option value="ADDRESS">지갑 주소</option>
              </select>
              <input type="text" class="form-control w-auto mr-1" placeholder="검색어" v-model="searchKeyword"
                     @keyup.enter="getList(1)">
              <button class="btn btn-primary" @click="getList(1)">검색</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th rowspan="2">NO</th>
                    <th>보낸 아이디</th>
                    <th rowspan="2">수량</th>
                    <th rowspan="2">수수료</th>
                    <th rowspan="2">합계</th>
                    <th>txid</th>
                    <th rowspan="2">입출금</th>
                    <th rowspan="2">진행 상태</th>
                    <th rowspan="2">생성일</th>
                    <th rowspan="2">안전출금</th>
                  </tr>
                  <tr>
                    <th>받는 아이디</th>
                    <th>수신 주소</th>
                  </tr>
                  </thead>
                  <tbody v-for="(item, index) in items" :key="item.transferIdx">
                  <tr>
                    <td rowspan="2" style="text-align: center">{{ index + 1 + (page - 1) * 10 }}</td>
                    <td>
                      {{ item.type === 'HOUSE_IN' ? item.opposite : item.type === 'TX_OUT' || item.type === 'HOUSE_OUT' ? item.owner : '' }}
                    </td>
                    <td rowspan="2" style="text-align: right">{{ $numberWithCommas(item.amount) }}</td>
                    <td rowspan="2" style="text-align: right">{{ item.feeAmount }}</td>
                    <td rowspan="2" style="text-align: right">
                      {{ $numberWithCommas(Number(item.amount) + Number(item.feeAmount)) }}
                    </td>
                    <td>{{ item.txid }}</td>
                    <td rowspan="2" style="text-align: center">{{ transType(item.type) }}</td>
                    <td rowspan="2" style="text-align: center">{{ transState(item.processState) }}</td>
                    <td rowspan="2" style="text-align: center">{{ item.date | moment('YYYY-MM-DD HH:mm:ss') }}</td>
                    <td rowspan="2" class="text-center">
                      <div class="btn-group mr-2"
                           v-show="item.type === 'TX_OUT' && item.processState === 'PENDING' && !hideBtns">
                        <button class="badge btn btn-success mr-1" @click="setData(item.transferIdx, true)">승인</button>
                        <button class="badge btn btn-danger" @click="setData(item.transferIdx, false)">거절</button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ item.type === 'TX_IN' || item.type === 'HOUSE_IN' ? item.owner : item.type === 'HOUSE_OUT' ? item.opposite : '' }}
                    </td>
                    <td>{{ item.addressTo }}</td>
                  </tr>
                  </tbody>
                  <tbody v-if="items.length === 0">
                  <tr>
                    <td colspan="10" style="text-align: center;">관련된 내역이 없습니다.</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer clearfix text-center row justify-content-center">
                <pagination ref="Pagination"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "../common/Pagination"

export default {
  name: 'WalletList',

  components: {
    Pagination,
  },

  data() {
    return {
      stateList: ['PENDING', 'DONE', 'ERROR', 'PROCESSING', 'DECLINED'],

      selectedTransactionType: '',
      selectedState: '',
      selectedSearchType: this.$route.params.email ? 'EMAIL' : '',
      searchKeyword: this.$route.params.email ? this.$route.params.email : '',
      size: '10',
      page: 1,
      total: 0,

      checkAll: false,
      items: [],
      hideBtns: false,
    }
  },

  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'wallet', this.$options.name)

    let page = this.$route.query.page
    if (typeof (page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if (typeof (size) !== 'undefined') {
      this.size = size
    }

    let tt = this.$route.query.tt
    if (typeof (tt) !== 'undefined') {
      this.selectedTransactionType = tt
    }

    let ss = this.$route.query.ss
    if (typeof (ss) !== 'undefined') {
      this.selectedState = ss
    }

    let st = this.$route.query.st
    if (typeof (st) !== 'undefined') {
      this.selectedSearchType = st
    }

    let sk = this.$route.query.sk
    if (typeof (sk) !== 'undefined') {
      this.searchKeyword = sk
    }

    this.getList(page)
  },

  methods: {
    setData(transferIdx, bool) {
      this.hideBtns = true

      let formData = new FormData()
      formData.append('idx', transferIdx)
      formData.append('status', bool)

      this.$post(this.$WALLET_SAFE, this.$options.name + Math.random(), formData, true, () => {
        this.notify('success', '처리 되었습니다.')
        this.getList(this.page)
      }, (result) => {
        this.httpError(result)
      })
    },

    transState(val) {
      if (val === 'PENDING') {
        return '대기중'
      } else if (val === 'DONE') {
        return '완료'
      } else if (val === 'ERROR') {
        return '에러'
      } else if (val === 'PROCESSING') {
        return '진행중'
      } else if (val === 'DECLINED') {
        return '거절'
      } else {
        return ''
      }
    },

    transType(val) {
      if (val === 'TX_IN') {
        return '외부 입금'
      } else if (val === 'TX_OUT') {
        return '외부 출금'
      } else if (val === 'HOUSE_IN') {
        return '내부 입금'
      } else if (val === 'HOUSE_OUT') {
        return '내부 출금'
      } else {
        return ''
      }
    },

    getList(page) {
      this.hideBtns = false

      if (!this.searchKeyword) {
        this.selectedSearchType = ''
      }

      if (!this.selectedSearchType) {
        this.searchKeyword = ''
      }

      let params = new URLSearchParams()
      if (typeof page === 'undefined') page = 1
      params.append('page', page)
      params.append('limit', this.size)
      params.append('isDeposit', this.selectedTransactionType)
      params.append('processState', this.selectedState)
      params.append('criteria', this.selectedSearchType)
      params.append('keyword', this.searchKeyword.trim())

      this.$get(`${this.$WALLET_HISTORY}?${params.toString()}`, 'getWalletList', true, (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {
            tt: this.selectedTransactionType,
            ss: this.selectedState,
            st: this.selectedSearchType,
            sk: this.searchKeyword,
          }, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }
        this.$router.replace({
          query: {
            tt: this.selectedTransactionType,
            ss: this.selectedState,
            st: this.selectedSearchType,
            sk: this.searchKeyword,
            page: this.page,
            size: this.size,
          },
        });
        this.items = result.data
      }, (result) => {
        console.log(result)
      })
    },

  },
  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },
  },
}
</script>

<style>
.table tbody + tbody {
  border-top: none;

}

.table thead th {
  vertical-align: middle;
}

.table td {
  vertical-align: middle;
}

</style>
