<template>
  <div style="padding: 100px">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">기타</h4>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title mr-2">Google OTP</h5>
                <small>구글 OTP 앱을 설치하고 이용해주세요.</small>
              </div>
              <div class="card-body text-center">
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>QRCode</strong>
                  </div>
                  <div class="col-sm-5">
                    <vue-qrcode :value="otpUrl" :width="200" :margin="1"></vue-qrcode>
                  </div>
                  <div class="col-sm-4">
                    <span></span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>Secret Text</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.otpKey" readonly>
                  </div>
                  <div class="col-sm-4">
                    <span></span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>인증코드 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="formData.otpCode"/>
                  </div>
                  <div class="col-sm-4">
                    <span></span>
                  </div>
                </div>
              </div>
              <div class="card-footer clearfix text-center row">
                <div class="col-sm-3">
                </div>
                <div class="col-sm-5">
                  <button type="button" class="btn btn-primary" @click="newData">OTP 등록</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'

export default {
  name: 'OTPSetting',

  components: {
    VueQrcode,
  },

  data() {
    return {
      otpUrl: '',
      formData: {
        otpKey: '',
        otpCode: '',
        twoFactorKey: '',
      },
      isTwoFactor: false,
    }
  },

  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'etc', this.$options.name)

    this.getData()
  },

  methods: {
    getData() {
      this.formData.twoFactorKey = this.$route.params.twoFactorKey
      this.$get(`${this.$ADMIN_OTP}?twoFactorKey=${this.formData.twoFactorKey}`, this.$options.name + '_getData', false, (result) => {
        this.otpUrl = result.data[0].otpUrl
        this.formData.otpKey = result.data[0].otpKey
      }, (result) => {
        this.httpError(result)
      })
    },

    newData() {
      let formData = new FormData()
      formData.append('twoFactorKey', this.formData.twoFactorKey)
      formData.append('key', this.formData.otpKey)
      formData.append('otpCode', this.formData.otpCode)

      this.$post(this.$ADMIN_OTP_SETTING, this.$options.name + '_newData', formData, false, (result) => {
        this.notify('success', result.message, false)

        this.$router.push({
          name: 'Login'
        })
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style>
</style>
