import axios from 'axios'

let requestOption = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
        timeout: 60000
    }
}
let requestOptionWithToken = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        },
        timeout: 60000
    }
}

let requestOptionForDownload = () => {
    return {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        timeout: 60000,
        responseType: 'blob',
    }
}
let requestOptionWithTokenForDownload = () => {
    return {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        },
        timeout: 60000,
        responseType: 'blob',
    }
}

let pending_get = {}
let pending_post = {}
let pending_put = {}
let pending_delete = {}

export default Object.freeze  ({
    install(Vue) {
        Vue.prototype.$DOMAIN = process.env.VUE_APP_DOMAIN

        Vue.prototype.$ADMIN_LOGIN = "/admin/login"
        Vue.prototype.$ADMIN_REFRESH_TOKEN = "/admin/refresh/token"
        Vue.prototype.$ADMIN_LOGIN_OTP = "/admin/login/otp"
        Vue.prototype.$ADMIN_OTP_SETTING = "/admin/otp/setting"
        Vue.prototype.$ADMIN_OTP = "/admin/otp"
        Vue.prototype.$ADMIN_LOGOUT = "/admin/logout"
        Vue.prototype.$USER_LIST = "/user/list"
        Vue.prototype.$USER_LIMIT = "/user/limit"
        Vue.prototype.$LOCKUP_PLAN = "/lockup/plan"
        Vue.prototype.$LOCKUP_HISTORY_USER = "/lockup/history/user"
        Vue.prototype.$LOCKUP_PLAN_ADD = "/lockup/plan/add"
        Vue.prototype.$LOCKUP_PLAN_LIST = "/lockup/plan/list"
        Vue.prototype.$LOCKUP_PLAN_REGISTER = "/lockup/plan/register"
        Vue.prototype.$LOCKUP_PLAN_DELETE = "/lockup/plan/delete"
        Vue.prototype.$LOCKUP_PLAN_FEE_INTERNAL = "/setting/fee/internal"
        Vue.prototype.$LOCKUP_PLAN_FEE_EXTERNAL = "/setting/fee/external"
        Vue.prototype.$LOCKUP_PLAN_FEE_DECIMAL = "/setting/fee/decimal"
        Vue.prototype.$LOCKUP_PLAN_MIN_INTERNAL = "/setting/min/internal"
        Vue.prototype.$LOCKUP_PLAN_MIN_EXTERNAL = "/setting/min/external"
        Vue.prototype.$LOCKUP_PLAN_FEE_INFO = "/setting/fee/info"
        Vue.prototype.$LOCKUP_PLAN_MIN_INFO = "/setting/min/info"
        Vue.prototype.$LOCKUP_PLAN_SUSPEND = "/lockup/plan/suspend"
        Vue.prototype.$LOCKUP_PLAN_CANCEL = "/lockup/plan/cancel"
        Vue.prototype.$LOCKUP_PLAN_CANCEL_PART = "/lockup/plan/cancel/part"
        Vue.prototype.$WALLET_HISTORY = "/wallet/history"
        Vue.prototype.$LOCKUP_HISTORY = "/lockup/history"
        Vue.prototype.$LOCKUP_PLAN_CANCEL = "/lockup/plan/cancel"
        Vue.prototype.$LOCKUP_HISTORY_DELAY = "/lockup/history/delay"
        Vue.prototype.$TRANSFER_WHITE = "/transfer/white"
        Vue.prototype.$TRANSFER_WHITE_DELETE = "/transfer/white/delete"
        Vue.prototype.$WALLET_SAFE = "/wallet/safe"
        Vue.prototype.$WALLET_DIRECT_REMITTANCE = "/wallet/direct/remittance"
        Vue.prototype.$STAKING_PLAN = "/staking/plan"
        Vue.prototype.$STAKING_CLOSE_STAKING = "/staking/close/staking"
        Vue.prototype.$STAKING_CLOSE_PLAN = "/staking/close/plan"
        Vue.prototype.$STAKING_PLAN_FIX = "/staking/plan/fix"
        Vue.prototype.$STAKING_PLAN_EXTRA = "/staking/plan/extra"
        Vue.prototype.$STAKING_FIX = "/staking/fix"
        Vue.prototype.$STAKING_EXTRA = "/staking/extra"
        Vue.prototype.$STAKING_USER_FIX = "/staking/user/fix"
        Vue.prototype.$STAKING_USER_EXTRA = "/staking/user/extra"
        Vue.prototype.$STAKING_FIX_DELAY = "/staking/fix/delay"
        Vue.prototype.$STAKING_EXTRA_DELAY = "/staking/extra/delay"
        Vue.prototype.$TERMS = "/terms"
        Vue.prototype.$BATCH_DIRECT = "/batch/direct"
        Vue.prototype.$BATCH_DIRECT_SAMPLE = "/batch/direct/sample"
        Vue.prototype.$WALLET_HISTORY_BUNNY = "/wallet/history/bunny"


        Vue.prototype.$post = function (callUrl, caller, postData, useToken, success, fail) {
            if (pending_post[arguments[0] + caller]) {
                console.log('request post fail : ' + arguments[0] + caller)
                return
            }
            pending_post[arguments[0] + caller] = true
            let _requestOption = requestOption()
            if(useToken) {
                let token = sessionStorage.getItem('token')
                if(token && token.length > 0) {
                    _requestOption = requestOptionWithToken()
                }
            }

            axios.post(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_post[arguments[0] + caller] = false
                if (response.data.result === true) {
                    success(response.data)
                } else {
                    fail(response)
                }
            }).catch((e) => {
                pending_post[arguments[0] + caller] = false
                fail(e.response)

                if (e.response.data.status === 403 || e.response.data.status === 401) {
                    this.$router.replace({name: 'Login'})
                    sessionStorage.clear()
                }
            })
        }

        Vue.prototype.$put = function (callUrl, caller, postData, useToken, success, fail) {
            if (pending_put[arguments[0] + caller]) {
                console.log('request put fail : ' + arguments[0] + caller)
                return
            }
            pending_put[arguments[0] + caller] = true
            let _requestOption = requestOption()

            if(useToken) {
                let token = sessionStorage.getItem('token')
                if(token && token.length > 0) {
                    _requestOption = requestOptionWithToken()
                }
            }

            axios.put(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_put[arguments[0] + caller] = false
                if (response.data.result === true) {
                    success(response.data)
                } else {
                    fail(response)
                }
            }).catch((e) => {
                pending_put[arguments[0] + caller] = false
                fail(e.response)

                if (e.response.data.status === 403 || e.response.data.status === 401) {
                    this.$router.replace({name: 'Login'})
                    sessionStorage.clear()
                }
            })
        }

        Vue.prototype.$delete = function (callUrl, caller, useToken, success, fail) {
            if (pending_delete[arguments[0] + caller]) {
                console.log('request delete fail : ' + arguments[0] + caller)
                return
            }
            pending_delete[arguments[0] + caller] = true
            let _requestOption = requestOption()

            if(useToken) {
                let token = sessionStorage.getItem('token')
                if(token && token.length > 0) {
                    _requestOption = requestOptionWithToken()
                }
            }


            axios.delete(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_delete[arguments[0] + caller] = false
                if (response.data.result === true) {
                    success(response.data)
                } else {
                    fail(response)
                }
            }).catch((e) => {
                pending_delete[arguments[0] + caller] = false
                fail(e.response)

                if (e.response.data.status === 403 || e.response.data.status === 401) {
                    this.$router.replace({name: 'Login'})
                    sessionStorage.clear()
                }
            })
        }

        Vue.prototype.$get = function (callUrl, caller, useToken, success, fail) {
            if (pending_get[arguments[0] + caller]) {
                console.log('request get fail : ' + arguments[0] + caller)
                return
            }
            pending_get[arguments[0] + caller] = true
            let _requestOption = requestOption()

            if(useToken) {
                let token = sessionStorage.getItem('token')
                if(token && token.length > 0) {
                    _requestOption = requestOptionWithToken()
                }
            }

            axios.get(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_get[arguments[0] + caller] = false
                if (response.data.result === true) {
                    success(response.data)
                } else {
                    fail(response)
                }
            }).catch(e => {
                pending_get[arguments[0] + caller] = false
                fail(e.response)

                console.log(e.response.data.status)
                if (e.response.data.status === 403 || e.response.data.status === 401) {
                    this.$router.replace({name: 'Login'})
                    sessionStorage.clear()
                }
            })
        }

        Vue.prototype.$download_get = function (callUrl, caller, img, others, fail){
            console.log(arguments[0])
            if (pending_post[arguments[0] + caller]) {
                return
            }
            pending_post[arguments[0] + caller] = true
            let _requestOption = requestOptionForDownload()
            let token = sessionStorage.getItem('token')
            if(token && token.length > 0) {
                _requestOption = requestOptionWithTokenForDownload
            }

            axios.get(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_post[arguments[0] + caller] = false
                if (response.headers['content-type'] === 'image/jpeg' || response.headers['content-type'] === 'image/png') {
                    img(response.request.responseURL)
                } else {
                    others(response)
                }
            }).catch(e => {
                pending_get[arguments[0] + caller] = false
                fail(e.response)
                if (e.response.data.status === 403 || e.response.data.status === 401) {
                    this.$router.replace({name: 'Login'})
                    sessionStorage.clear()
                }
            })
        },

        Vue.prototype.$download = function (callUrl, caller, useToken, success, fail){
            console.log(arguments[0])
            if (pending_delete[arguments[0] + caller]) {
                return
            }
            pending_delete[arguments[0] + caller] = true
            let _requestOption = requestOptionForDownload()
            if (useToken) {
                let token = sessionStorage.getItem('token')
                if (token && token.length > 0) {
                    _requestOption = requestOptionWithTokenForDownload()
                }
            }
            axios.get(this.$DOMAIN + callUrl, _requestOption).then(response => {
                pending_get[arguments[0] + caller] = false
                if (response.headers['content-type'] === 'application/octet-stream') {
                    success(response)
                } else {
                    fail(response.data)
                }
            }).catch(e => {
                pending_get[arguments[0] + caller] = false
                fail(e.response)
                if (e.response.data.status === 403 || e.response.data.status === 401) {
                    this.$router.replace({name: 'Login'})
                    sessionStorage.clear()
                }
            })
        }
    }
})
